body {
  background-color: #f8f9fa;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  color: #343a40;
}

h2 {
  color: #495057;
}

.table-hover tbody tr:hover {
  background-color: #f1f3f5;
}

.card {
  border: none;
  border-radius: 0.5rem;
}

.card-body {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.3s, border-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.table thead {
  background-color: #343a40;
  color: #ffffff;
}

.table tbody tr {
  transition: background-color 0.3s;
}

.table tbody tr:hover {
  background-color: #dee2e6;
}

